import React from "react"
import { PPCBanner } from "./ppc-hero-types"
import "./ppc-hero.module.scss"
import "./ppc-coupon.module.scss"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"
import { getBackgroundImageUrlByBreakpoint } from "assets/helper/getBackgroundImageUrlByBreakpoint"
import { HeroContent } from "../HeroContent"
import { FormBuilder } from "components/forms/FormBuilder"
import { Coupon } from "components/coupons/Coupon"
import { KontentItemForm } from "components/forms/formBuilder-types"
import { CouponType } from "components/coupons/coupons"
import { Image } from "components/image/Image"

interface HeroProps {
  banner: PPCBanner
}

export const PpcHero = ({ banner }: HeroProps) => {
  const {
    background_image,
    heading,
    sub_heading,
    buttons,
    components,
    secondary_image,
  } = banner
  const { imageBannerColorOverlay } = useColorAreaSettings()
  const form = (components?.modular_content as KontentItemForm[]).filter(
    component => component?.system?.type === "form"
  )[0]?.elements
  const coupon = (components?.modular_content as CouponType[]).filter(
    component => component?.system?.type === "coupon"
  )[0]?.elements
  const backgroundImageCSSVariable = background_image?.value[0]?.url
    ? getBackgroundImageUrlByBreakpoint({
        variableName: "hero-background-image",
        imageUrl: background_image.value[0].url,
        breakpoints: {
          mobile: {
            width: 801,
          },
        },
      })
    : {}

  const overrideBackground = imageBannerColorOverlay
    ? `${imageBannerColorOverlay}--backgroundColor`
    : ""

  return (
    <section
      className={`ppc-hero ${overrideBackground}`}
      style={backgroundImageCSSVariable}
    >
      <div className={`fr-container ppc-hero__wrapper`}>
        <HeroContent
          baseClassName="ppc-banner-content"
          heading={heading?.value}
          subHeading={sub_heading}
          buttons={buttons?.value}
        />

        {form?.fields && (
          <FormBuilder
            ctaText={form?.submit_button_text?.value}
            submissionMessageHeading={form?.submission_message_heading?.value}
            submissionMessageBody={form?.submission_message_body?.value}
            fields={form.fields}
            formGridStyle={form?.form_column_layout?.value[0]?.codename}
            customId={form?.custom_id?.value}
            isLandingPage={true}
          />
        )}

        <div className="ppc-hero__adds">
          {coupon && (
            <Coupon
              baseClassName="ppc-coupon"
              couponValue={coupon?.coupon_value?.value}
              subHeading={coupon?.sub_heading?.value}
              disclaimer={coupon?.disclaimer?.value}
              hideLogo
              hasDollarSignSuperset={coupon?.coupon_value?.value.includes("$")}
            />
          )}
          {secondary_image?.value[0] && (
            <Image
              image={secondary_image?.value[0]}
              className="secondary-image"
              objectFit="cover"
            />
          )}
        </div>
      </div>
    </section>
  )
}
