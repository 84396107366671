import React, { useEffect, ReactNode, useCallback, useState } from "react"
import { Helmet } from "react-helmet"
import { setTrackingCookies } from "assets/helper/setTrackingCookies"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import { useLogos } from "hooks/queries/settings/useLogos"
import { Footer } from "components/footer/Footer"
import { Header } from "components/main-header/Header"
import { useNavigationItems } from "hooks/useNavigationItems"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { LayoutContext } from "contexts/components/LayoutContext"
import "assets/scss/4-templates/landing-page-template.module.scss"
import { TrackingNumberModal } from "components/modal/TrackingNumberModal/TrackingNumberModal"
import { PhoneIcon } from "./main-header/PhoneIcon"

interface LandingPageLayoutProps {
  children: ReactNode
  location: string
  pathName: string
  linkedPagesUrlPathCodex: Record<string, string>
  mobilePreload?: string
  parentSlug?: string
  customTrackingNum?: string
}

export const LandingPageLayout = ({
  children,
  location,
  pathName,
  mobilePreload,
  linkedPagesUrlPathCodex,
  parentSlug,
  customTrackingNum,
}: LandingPageLayoutProps) => {
  const { size, isScrolled } = useWindowDimensions()

  let scrollClass = ""

  if (isScrolled) scrollClass = "page-content--scroll-version"

  const { customCSSUrl, trackingNumbers, usePhoneNumberNotTrackingNumber } =
    useGeneralSettings()

  const trackingNumber = trackingNumbers[0]?.trackingNumber ?? ""
  const phoneNumber = trackingNumbers[0]?.phoneNumber ?? ""
  const contactNumber = usePhoneNumberNotTrackingNumber
    ? phoneNumber
    : trackingNumber

  const { footerNavigation, creditsNavigation } = useNavigationItems()

  const { footerLogo, headerLogo } = useLogos()
  const [isTrackingModalOpen, setTrackingModalOpen] = useState(false)

  const handleOpenTrackingModal = useCallback(() => {
    setTrackingModalOpen(true)
  }, [])

  const handleCloseTrackingModal = useCallback(() => {
    setTrackingModalOpen(false)
  }, [])

  useEffect(() => {
    setTrackingCookies()
    if (window) {
      window.addEventListener("message", event => {
        if (
          event.data.type === "hsFormCallback" &&
          event.data.eventName === "onFormSubmit"
        ) {
          console.log("Form Submitted!", event)
          window.localStorage.setItem("leadSaved", "True")
        }
      })
    }
  }, [])

  return (
    <LayoutContext.Provider
      value={{
        linkedPagesUrlPathCodex,
        breadcrumbLocation: pathName,
        slug: location,
        parentSlug,
        isLandingPage: true,
      }}
    >
      <div className={`wrapper wrapper--landing-page`}>
        <Helmet>
          {customCSSUrl && <link rel="stylesheet" href={customCSSUrl} />}
          {mobilePreload && size > 0 && size < 768 && (
            <link rel="preload" as="image" href={mobilePreload} />
          )}
        </Helmet>

        <Header logo={headerLogo} hasToggleMenu={false} isLandingPage={true}>
          <>
            {contactNumber && size > 768 ? (
              <div className="landing-page-tracking">
                <span>Call Today!</span>
                <a
                  href={`tel: ${
                    customTrackingNum ? customTrackingNum : contactNumber
                  }`}
                >
                  {customTrackingNum ? customTrackingNum : contactNumber}
                </a>
              </div>
            ) : (
              <PhoneIcon
                isTrackingModalOpen={isTrackingModalOpen}
                handleOpenTrackingModal={handleOpenTrackingModal}
                isLandingPage={true}
                customTrackingNum={customTrackingNum}
              />
            )}
          </>
        </Header>

        <main className={`page-content--landing-page ${scrollClass}`}>
          {children}
        </main>

        <Footer
          logo={footerLogo}
          creditsNavigation={creditsNavigation}
          footerNavigation={footerNavigation}
          customTrackingNum={customTrackingNum}
          isLandingPage={true}
        />

        {isTrackingModalOpen && (
          <TrackingNumberModal onCloseModal={handleCloseTrackingModal} />
        )}
      </div>
    </LayoutContext.Provider>
  )
}
